import React, { useState } from "react";
import "./Amount.css";
import { Dropdown } from "react-bootstrap";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
const Amount = () => {
  const navigate = useNavigate();

  const [selectedCurrency, setSelectedCurrency] = useState("USDT"); // Default value is 'ETH'
  const handleSelect = (eventKey) => {
    setSelectedCurrency(eventKey); // Update selectedCurrency when a new item is clicked
  };
  return (
    <>
      <div className="Amount-Main">
        <div className="Amount-Top-Bar">
          <img
            src="/Images/backbutton.svg"
            alt=""
            onClick={() => {
              navigate(-1);
            }}
            className="back-button"
          />
          <div className="Round-Icon">
            <img src="/Images/threeDot.svg" alt="" />
          </div>
        </div>

        <div className="Amount-Wallet-Address-Main">
          <div className="Amount-Wallet-Address-Main-1">
            <p className="Amount-Wallet-Address-Text">Wallet Address</p>
            <input
              placeholder="Enter Wallet Address"
              className="Wallet-Input"
            />
          </div>
          <div className="Amount-Icons-Main">
            <div className="Amount-Scan-Div1">
              <img src="/Images/Scan.svg" alt="" className="scan-img" />
            </div>
            <div className="Amount-Scan-Div2">
              <img src="/Images/Copy.svg" alt="" className="copy-img" />
            </div>
          </div>
        </div>

        <div className="Amount-DropDown-Div">
          <div>
            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle className="Amount-Dropdown">
                {/* Show the selected currency */}
                <p className="Amount-Dropdown-Text">{selectedCurrency}</p>
                <img
                  src="/Images/DropDownArrow.svg"
                  alt=""
                  className="Amount-Dropdown-Arrow"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className="Amount-Dropdown-Menu">
                {/* Pass the currency name as the eventKey */}
                <Dropdown.Item eventKey="USDT" className="Amount-Dropdown-item">
                  USDT
                </Dropdown.Item>
                <Dropdown.Item eventKey="ETH" className="Amount-Dropdown-item">
                  ETH
                </Dropdown.Item>
                <Dropdown.Item eventKey="SOL" className="Amount-Dropdown-item">
                  SOL
                </Dropdown.Item>
                <Dropdown.Item eventKey="XRP" className="Amount-Dropdown-item">
                  XRP
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="Amount-Point-Dollar-Div">
            <input
              className="Amount-Points-Text"
              type="number"
              placeholder="0.021"
            />
            <p className="Amount-Dollar-Text">$24,306.00</p>
          </div>
        </div>

        <div className="Amount-Keyboard">
          {/* <div className='Amount-Keyboard-Numbering-div'>
                        <p className='Amount-Keyboard-Numbering'>1</p>
                        <p className='Amount-Keyboard-Numbering'>4</p>
                        <p className='Amount-Keyboard-Numbering'>7</p>
                        <p className='Amount-Keyboard-Numbering'>.</p>
                    </div>
                    <div className='Amount-Keyboard-Numbering-div'>
                        <p className='Amount-Keyboard-Numbering'>2</p>
                        <p className='Amount-Keyboard-Numbering'>5</p>
                        <p className='Amount-Keyboard-Numbering'>8</p>
                        <p className='Amount-Keyboard-Numbering'>0</p>
                    </div>
                    <div className='Amount-Keyboard-Numbering-div'>
                        <p className='Amount-Keyboard-Numbering'>3</p>
                        <p className='Amount-Keyboard-Numbering'>6</p>
                        <p className='Amount-Keyboard-Numbering'>9</p>
                        <p> <img src='/Images/CrossKeyboard.svg' alt='' />
                        </p>
                    </div> */}

          <img
            src="/Images/verification/Keyboard.svg"
            className="keyboardimage"
            alt=""
          />
        </div>

        <button
          className="Amount-Next-Button"
          onClick={() => {
            navigate("/Verification");
          }}
        >
          Next
        </button>
      </div>
      <Navbar />
    </>
  );
};

export default Amount;
