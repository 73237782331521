import React, { useState } from "react";
import "./Verification.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
const Verification = () => {
  const [selectedWallet, setSelectedWallet] = useState("USDT Wallet");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelect = (wallet) => {
    setSelectedWallet(wallet);
    setIsDropdownOpen(false);
  };
  const navigate = useNavigate();
  const handleToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);
  };
  return (
    <>
      <div className="Verification_main">
        <div className="Verification_top">
          <img
            src="/Images/verification/back.svg"
            alt=""
            onClick={() => {
              navigate(-1);
            }}
          />
          <img src="/Images/verification/threedot.svg" alt="" />
        </div>

        <div className="Verification_top2">
          <div style={{ position: "relative" }} className="dropdown-container">
            <Dropdown
              className="ddp"
              onToggle={(isOpen) => handleToggle(isOpen)}
              style={{ marginBottom: isDropdownOpen ? "150px" : "0"}}
            >
              <Dropdown.Toggle
                className="Verification_top2_Dropdown"
                id="dropdown-basic"
              >
                <p className="Verification_top2_Dropdown_text">{selectedWallet}</p>
                <img
                  src="/Images/verification/ddpArrow.svg"
                  className="ddpimage"
                  alt="Dropdown arrow"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className="menuitemstyle">
                <Dropdown.Item
                  className="menuoptions"
                  onClick={() => handleSelect("USDT Wallet")}
                >
                  USDT Wallet
                </Dropdown.Item>
                <Dropdown.Item
                  className="menuoptions"
                  onClick={() => handleSelect("Bitcoin Wallet")}
                >
                  Bitcoin Wallet
                </Dropdown.Item>
                <Dropdown.Item
                  className="menuoptions"
                  onClick={() => handleSelect("Solana Wallet")}
                >
                  Solana Wallet
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="Verification_top2_d2">
            <p className="Verification_top2_d2-text1">Wallet Address</p>
            <input
              type="text"
              placeholder="Enter Wallet Address"
              className="inputstyle__verify"
            />
          </div>
          <div className="Verification_top2_d3">
            <p className="Verification_top2_d3_text1">Amount</p>
            <div className="Verification_top2_d3_1">
              <div className="inner___top">
                <p className="Verification_top2_d3_1-text">Network fee</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p className="inerrr_text1">2.0234</p>
                  <p className="inerrr_text2">0.203 USDT</p>
                </div>
              </div>
              <img
                src="/Images/verification/Line.png"
                className="imgline"
                alt="______"
              />
              <p className="Verification_top2_d3_1-text1">Total Amount</p>
              <p className="inerrr_text1">10.032 USDT </p>
              <p className="inerrr_text2">$ 1029.01</p>
            </div>
          </div>
        </div>

        <button
          className="bottom_button"
          onClick={() => {
            navigate("/Complete");
          }}
        >
          Complete
        </button>
      </div>
      <Navbar />
    </>
  );
};

export default Verification;
