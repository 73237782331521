
import React, { useState } from 'react'
import './Dashboard.css'
import Navbar from '../../Components/Navbar/Navbar'
import { Drawer } from "@mui/material";
import '../../Components/Drawer/Drawer.css'
import CallMadeRoundedIcon from '@mui/icons-material/CallMadeRounded';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const [Act, setAct] = useState();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState(100); // Initial height in px
  const [startY, setStartY] = useState(0);

  const minDrawerHeight = 100; // Min height when collapsed
  const maxDrawerHeight = window.innerHeight - 50; // Max height when expanded

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartY(e.touches[0].clientY);
  };
  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);

  };
  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const currentY = e.touches[0].clientY;
    const distanceDragged = startY - currentY; // How much you've dragged upwards
    const newHeight = Math.min(Math.max(minDrawerHeight, drawerHeight + distanceDragged), maxDrawerHeight);

    setDrawerHeight(newHeight);
    setStartY(currentY); // Update startY for smooth dragging
    e.preventDefault();
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleimage = (active) => {
    setAct(active);
    if (active === "A") { navigate('/amount') }
    else if (active === "B") { }
  }

  return (
    <div>
      <div className='Dashboard-Main'>
        <div className='Dashboard-Topbar'>
          <img src='/Images/scandashboard.svg' alt='' />
          <img src='/Images/addicon.svg' alt='' />
        </div>
        <div className='Top-Hello-Div'>
          <p className='Dashboard-Hello-Text'>Hello</p>
          <p className='Name-Hello-Text'>Abdellah!</p>
          <p className='Whats-on-Your-Text'>What’s on your mind today ?</p>
        </div>
        <div className='Dashboard-Wallet-Box'>
          <p className='Wallet-Text'>USDT Wallet</p>

          <p className='Dashboard-Dollar-text'>
            <span className='Dollar-Sign-Text'>$ </span>6,500,000
          </p>
          <div className='Dashboard-Buttons-Text'>
            <div className='Send-Receive-Buy-Div' onClick={() => handleimage("A")} >
              {/* <img src='/Images/send.svg' alt='' className='send-image' /> */}
              <div className='icondiv'>
                <CallMadeRoundedIcon   className='icon111'/>
              </div>

              <p className='Buttons-Text'>Send</p>
            </div>
            <div className='Send-Receive-Buy-Div' onClick={() => handleimage("B")}>
              <img src='/Images/Receive.svg' alt='' className='send-image' />
              <p className='Buttons-Text'>Receive</p>
            </div>
            <div className='Send-Receive-Buy-Div' onClick={() => handleimage("C")}>
              <img src='/Images/Buy.svg' alt='' className='send-image' />
              <p className='Buttons-Text'>Buy</p>
            </div>
          </div>
          {/* <img src='/Images/line.svg' onClick={handleToggleDrawer} alt='' /> */}
        </div>


        <div
          className="Main-Drawer"
          style={{
            height: `${drawerHeight}px`, // Set dynamic height
            transition: isDragging ? 'none' : 'height 0.3s ease', // Smooth transition when not dragging
          }}

        >
          <div
            className='imagetop'
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img src='/Images/line2.svg' style={{ width: "96px", marginBottom: "10px" }} alt=''

            />
          </div>
          <div className="Main-dives-drawer-final">
            <div className="Main-Drawer-Dives">
              <div className="First-Content-Div">
                <img src='/Images/eth.svg' alt='' className="btc-image" />
                <div className="two-text-div">
                  <p className="btc-text">ETH</p>
                  <p className="bitCoin">Ethereum</p>
                </div>
                <img src='/Images/greenGraph.svg' alt='' className="Graph-Image" />
              </div>

              <div className="two-text-div2">
                <p className="btc-text">30.6924</p>
                <p className="bitCoin">$95,139</p>
              </div>
            </div>
            <div className="Main-Drawer-Dives">
              <div className="First-Content-Div">
                <img src='/Images/btc.svg' alt='' className="btc-image" />
                <div className="two-text-div">
                  <p className="btc-text">BTC</p>
                  <p className="bitCoin">Bitcoin</p>
                </div>
                <img src='/Images/redGraph.svg' alt='' className="Graph-Image" />
              </div>

              <div className="two-text-div2">
                <p className="btc-text">25.3987</p>
                <p className="bitCoin">$2,275,348.597</p>
              </div>
            </div>
            <div className="Main-Drawer-Dives">
              <div className="First-Content-Div">
                <img src='/Images/eth.svg' alt='' className="btc-image" />
                <div className="two-text-div">
                  <p className="btc-text">ETH</p>
                  <p className="bitCoin">Ethereum</p>
                </div>
                <img src='/Images/greenGraph.svg' alt='' className="Graph-Image" />
              </div>

              <div className="two-text-div2">
                <p className="btc-text">30.6924</p>
                <p className="bitCoin">$95,139</p>
              </div>
            </div>

            <div className="Main-Drawer-Dives">
              <div className="First-Content-Div">
                <img src='/Images/ltc.svg' alt='' className="btc-image" />
                <div className="two-text-div">
                  <p className="btc-text">LTC</p>
                  <p className="bitCoin">Litecoin</p>
                </div>
                <img src='/Images/greenGraph.svg' alt='' className="Graph-Image" />
              </div>

              <div className="two-text-div2">
                <p className="btc-text">8,522.727</p>
                <p className="bitCoin">$751,287.078</p>
              </div>
            </div>
            <div className="Main-Drawer-Dives">
              <div className="First-Content-Div">
                <img src='/Images/eth.svg' alt='' className="btc-image" />
                <div className="two-text-div">
                  <p className="btc-text">ETH</p>
                  <p className="bitCoin">Ethereum</p>
                </div>
                <img src='/Images/greenGraph.svg' alt='' className="Graph-Image" />
              </div>

              <div className="two-text-div2">
             
                <p className="btc-text">30.6924</p>
                <p className="bitCoin">$95,139</p>
              </div>
            </div>

            <div className="Main-Drawer-Dives">
              <div className="First-Content-Div">
                <img src='/Images/trx.svg' alt='' className="btc-image" />
                <div className="two-text-div">
                  <p className="btc-text">TRX</p>
                  <p className="bitCoin">Tron</p>
                </div>
                <img src='/Images/redGraph.svg' alt='' className="Graph-Image" />
              </div>

              <div className="two-text-div2">
                <p className="btc-text">3,158,567.2</p>
                <p className="bitCoin">$652,348.782</p>
              </div>
            </div>




            <div className="Main-Drawer-Dives">
              <div className="First-Content-Div">
                <img src='/Images/eth.svg' alt='' className="btc-image" />
                <div className="two-text-div">
                  <p className="btc-text">ETH</p>
                  <p className="bitCoin">Ethereum</p>
                </div>
                <img src='/Images/greenGraph.svg' alt='' className="Graph-Image" />
              </div>

              <div className="two-text-div2">
              <p className="btc-text">30.6924</p>
              <p className="bitCoin">$95,139</p>
              </div>
            </div>
            <div className="Main-Drawer-Dives">
              <div className="First-Content-Div">
                <img src='/Images/eth.svg' alt='' className="btc-image" />
                <div className="two-text-div">
                  <p className="btc-text">ETH</p>
                  <p className="bitCoin">Ethereum</p>
                </div>
                <img src='/Images/greenGraph.svg' alt='' className="Graph-Image" />
              </div>

              <div className="two-text-div2">
              <p className="btc-text">30.6924</p>
              <p className="bitCoin">$95,139</p>
              </div>
            </div>

          </div>
        </div>

      </div>
      <Navbar />
    </div>
  )
}

export default Dashboard;
