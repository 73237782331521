import React,{useState} from 'react'
import './Navbar.css'
const Navbar = () => {
  const[act,setAct]=useState("A");
const handleActive=(Active)=>
  {
    setAct(Active);
  }
  return (
    <div className='Navbar_main'>
        
        <img src={act==="A" ? '/Images/navbar/nav1.svg':'/Images/navbar/nav_1.svg'}  onClick={()=>handleActive("A")} alt='....'/>
        <img src={act==="B" ? '/Images/navbar/nav2b.svg':'/Images/navbar/nav2.svg'}  onClick={()=>handleActive("B")} alt='....'/>
        <img src={act==="C" ? '/Images/navbar/nav3b.svg':'/Images/navbar/nav3.svg'}  onClick={()=>handleActive("C")} alt='....'/>
        <img src={act==="D" ? '/Images/navbar/nav4b.svg':'/Images/navbar/nav4.svg'}  onClick={()=>handleActive("D")} alt='....'/>
      
    </div>
  )
}

export default Navbar
