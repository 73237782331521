import './App.css';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Dashboard from './Pages/Dashboard/Dashboard';
import Verification from './Pages/Verification/Verification';
import Amount from './Pages/Amount/Amount';
import Complete from './Pages/Complete/Complete';
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Dashboard/>}/>
        <Route path='/Verification' element={<Verification/>}/>
        <Route path='/Amount' element={<Amount/>}/>
        <Route path='/Complete' element={<Complete/>}/>
      </Routes>

    </Router>
  );
}

export default App;
