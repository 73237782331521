import React from "react";
import "./Complete.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
const Complete = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="Verification_main">
        <div className="Complete_top">
          <img src="/Images/verification/threedot.svg" alt="" />
        </div>

        <div className="completetop2">
          <div className="Complete_top2_d3_1">
            <div className="inner___top">
              <p className="Verification_top2_d3_1-text">Network fee</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <p className="inerrr_text1">2.0234</p>
                <p className="inerrr_text2">0.203 USDT</p>
              </div>
            </div>

            <img
              src="/Images/verification/Line.png"
              className="imgline"
              alt="______"
            />
            <div className="complete_m_inner">
              <div className="inner____in">
                <p className="inerrr_text2">From</p>
                <p className="Verification_top2_d3_1-text">Bc1q87...34pm4</p>
              </div>
              <div className="inner____in">
                <p className="inerrr_text2">To</p>
                <p className="Verification_top2_d3_1-text">OxTs345.....9283</p>
              </div>
              <div className="inner____in">
                <p className="inerrr_text2">Date</p>
                <p className="Verification_top2_d3_1-text">Mar 4 at 2:03 PM</p>
              </div>
              <div className="inner____in">
                <p className="inerrr_text2">Nonce</p>
                <p className="Verification_top2_d3_1-text">#0</p>
              </div>
            </div>
            <img
              src="/Images/verification/Line.png"
              className="imgline"
              alt="______"
            />
            <p className="Verification_top2_d3_1-text1">Total Amount</p>
            <p className="inerrr_text1">10.032 USDT</p>
            <p className="inerrr_text2">$ 1029.01</p>
          </div>
        </div>

        <button
          className="bottom_button"
          onClick={() => {
            navigate("/");
          }}
        >
          Home
        </button>
      </div>
      <Navbar />
    </>
  );
};

export default Complete;
